<template>
    <div class="mod-config" >
        <div class="wxts_msg_search">
            <span>配置大屏--{{projectName}}</span>
            <div class="f_t">
                <el-button-group>
                    <el-button size="mini" type="success" @click="deployBscreen()" :loading="dataListLoading">刷新</el-button>
                    <el-button size="mini" type="primary" @click="addOrUpdateHandle()">新增</el-button>
                    <el-button size="mini" type="primary" @click="cancelHandle()" :loading="isOnSubmit" icon="el-icon-back">返回</el-button>
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border v-loading="dataListLoading"  style="width: 100%;">
            <el-table-column prop="projectName" header-align="center" align="center" label="用户名"></el-table-column>
            <el-table-column prop="bscreenName" header-align="center" align="center" label="大屏名称"></el-table-column>
            <el-table-column header-align="center" align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small"  @click="deleteHandle(scope.row)"><span style="color: red">删除</span></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update :existList="dataList" v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="deployBscreen"></add-or-update>
    </div>
</template>

<script>
    import AddOrUpdate from './addBscreen'
    import { getList , deleteData } from '@/api/hbi/project/bscreen.js'

    export default {
        data () {
            return {
                dataForm: {
                    projectId: null
                },
                isOnSubmit: false,
                dataList: [],
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false,
                projectName: ""
            }
        },
        components: {
            AddOrUpdate
        },
        methods: {
            //获取数据列表
            deployBscreen(user) {
                if(user && user.userId){
                    this.dataForm.projectId = user.userId
                    this.projectName = user.username
                }
                this.dataListLoading = true;
                getList(this.dataForm.projectId).then(({data}) => {
                    if (data && data.code == 0) {
                        this.dataList = data.data;
                    }
                }).finally(() => {
                    this.dataListLoading = false;
                });
            },


            /**
             * 返回上一页
             */
            cancelHandle() {
                this.backMainPage(false);
            },
            /**
             * 关闭当前页返回上一页
             * @param refresh 返回后是否刷新列表
             */
            backMainPage(refresh) {
                this.$emit('refreshDataList', refresh)
            },

            // 新增 / 修改
            addOrUpdateHandle() {
                this.addOrUpdateVisible = true;
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(this.dataForm.projectId);
                });
            },

            // 删除
            deleteHandle(row) {
                const data_ = {
                    'bscreenId': row.bscreenId,
                    'projectId': row.projectId,
                }

                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteData(data_).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deployBscreen()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.dataListLoading = false
                    })
                }).catch(() => {
                })
            },
        }
    }
</script>
