<template>
  <div>
    <el-dialog
        title=" 选择大屏"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="60%">
      <el-row :gutter="20" style="margin-bottom: 0px;">
        <el-col :span="8">
          <el-form :inline="true" :model="categoryForm">
            <table class="detailTable_search" style="width: 100%">
              <tr>
                <td class="titleTd width80px">分组名称</td>
                <td class="valueTd">
                  <el-input v-model="categoryForm.name" placeholder="使用分组名称过滤列表，字母不区分大小写"
                            clearable></el-input>
                </td>
              </tr>
            </table>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="20">

        <el-col :span="8">
          <div class="wxts_msg_search">
            <span>分组展示</span>
            <div class="f_t">
              <el-button-group>
                <el-button @click="getCategoryList()" :loading="categoryLoading">查询</el-button>
              </el-button-group>
            </div>
          </div>
          <div class="data_tree">
            <el-table :data="categoryList" border v-loading="categoryLoading" highlight-current-row
                      @current-change="categoryChangeHandle"
                      style="width: 100%;">
              <el-table-column prop="categoryKey" header-align="center" align="center" label="分组名称">
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="16">
          <el-form :inline="true" :model="dataForm">
            <table class="detailTable_search" style="width: 100%">
              <tr>
                <td class="titleTd width80px">大屏名称</td>
                <td class="valueTd">
                  <el-input v-model="dataForm.title" placeholder="配置名称" clearable></el-input>
                </td>
              </tr>
            </table>
          </el-form>

          <div class="wxts_msg_search">
            <span>大屏模板列表{{categoryName ? ('('+categoryName+')') : ''}}</span>
            <div class="f_t">
              <el-button-group>
                <el-button @click="currentChangeHandle(1)" :disabled="dataListLoading">查询</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;">
            <el-table-column prop="category" header-align="center" align="center" label="分组名称" width="120">
              <template slot-scope="scope">
                {{getCategoryName(scope.row.category)}}
              </template>
            </el-table-column>
            <el-table-column prop="title" header-align="center" align="center" label="大屏名称">
            </el-table-column>

            <el-table-column header-align="center" align="center" width="150" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="addHandle(scope.row)">带回</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @size-change="sizeChangeHandle" class="mod-footer"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalCount"
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {saveOrUpdateBscrren} from '@/api/hbi/project.js'
import {getList} from '@/api/avueData/visual.js';
import {getList as getCategoryList} from '@/api/avueData/category.js'

export default {
  data() {
    return {
      visible: false,
      categoryList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      categoryLoading: false,
      categoryId: '',
      categoryName: '',

      categoryForm: {
        name: '',
      },
      dataForm: {
        title: null,
        status: 2,
        projectId: null
      },
    }
  },

  methods: {

    init(projectId) {
      this.dataForm.projectId = projectId
      this.visible = true
      this.categoryForm.name = null
      this.dataForm.title = null
      //获取已发布的大屏
      this.getCategoryList()
    },

    /**
     *  选中某个分组时
     */
    handleCurrentChange(val) {
      this.categoryId = val.id
      this.categoryName = val.name
      //根据分组ID查询配置列表的数据
      this.getDataList();
    },
    getCategoryName(value) {

      for (let i = 0; i < this.categoryList.length; i++) {
        if (this.categoryList[i].categoryValue == value) {
          return this.categoryList[i].categoryKey
        }
      }

      return null
    },
    // 获取分组展示数据列表
    getCategoryList() {
      this.categoryLoading = true
      const params_ = {
        categoryValue: this.categoryForm.name
      }
      getCategoryList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.categoryList = data.data

          this.categoryName = '';
          this.categoryId = ''
        } else {
          this.categoryList = []
          if (data && data.msg)
            this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.categoryLoading = false
        this.getDataList()

      })
    },
    categoryChangeHandle(val) {
      this.categoryId = val?val.categoryValue:''
      this.categoryName = val?val.categoryValue:''

      this.getDataList()

    },
    //根据分组ID获取数据
    getDataList() {
      this.dataListLoading = true;
      getList({
        category: this.categoryId,
        title: this.dataForm.title,
        status: this.dataForm.status,
        page: this.pageIndex,
        limit: this.pageSize
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
        }
      })
          .finally(() => {
            this.dataListLoading = false;
          });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    // 带回
    addHandle(row) {
      this.isOnSubmit = true
      const data_ = {
        'bscreenId': row.id,
        'projectId': this.dataForm.projectId,
      }
      saveOrUpdateBscrren(data_).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 500
          })
          this.visible = false
          this.$emit('refreshDataList')
        } else {
          this.$message.error("已带回，请勿重复操作")
        }
      }).catch((err) => {
        this.$message.error(err)
      }).finally(() => {
        this.isOnSubmit = false
      })

    },


  }
}
</script>
